<template>
  <v-app v-resize="handleResize">
    <CDNavbar
      id="nav"
      app
      clipped-left
      :title="$t('account')"
      home-link="https://www.azena.com"
    >
      <template v-slot:content-end>
        <CDHomeSwitcher
          name="dropdown"
          :is-developer="isDeveloper"
          :is-integrator="isIntegrator"
          :dropdown-links="dropdownLinks"
        />
        <CDSeparator />
        <CDButtonText name="signOut" class="my-0 mx-2" @click="logOut()">
          {{ $t("logout") }}
        </CDButtonText>
      </template>
      <template v-slot:content-end-mobile>
        <CDHomeSwitcher
          :is-developer="isDeveloper"
          :is-integrator="isIntegrator"
          :dropdown-links="dropdownLinks"
        />
        <CDSeparator />
        <CDButtonText name="signOut" class="my-0 mx-2" @click="logOut()">
          {{ $t("logout") }}
        </CDButtonText>
      </template>
    </CDNavbar>
    <CDSidebar app clipped permanent>
      <template v-for="navItem in nav">
        <CDSidebarItem
          :key="navItem.title"
          :icon="navItem.icon"
          :text="$t('nav.' + navItem.title)"
          :active="activeTab === navItem.title"
          @click="pushToRouter(navItem.title)"
        />
      </template>
    </CDSidebar>
    <MessageBox />
    <v-main>
      <router-view class="router-view" />
      <CDNeedHelpButton :logged-in="true" />
      <CDFooter
          absolute
          copyright="© 2024 Bosch Digital Commerce GmbH. All rights reserved."
          :links="footerLinks"
      />
      <CDCookieBanner />
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "./styles/modal.scss";
.router-view {
  margin-bottom: 24px;
}
</style>

<script>
import MessageBox from "./views/Components/MessageBox";

import { accountActionTypes, commonActionTypes } from "./store/actionTypes";
import { sastLinks } from "./utils/enums";
import { ACCOUNT_NAMESPACE } from "./store/namespaces";
import { mapState } from "vuex";
import config from "../config";
import { VApp, VMain } from "vuetify/lib";
import { Resize } from "vuetify/lib/directives";

export default {
  name: "App",
  components: {
    MessageBox,
    VApp,
    VMain,
  },
  data() {
    return {
      fullName: this.$keycloak.fullName,
      sastLinks,
      nav: [
        { title: "profile", icon: "$user" },
        { title: "company", icon: "$company" },
      ],
    };
  },
  directives: {
    Resize,
  },
  mounted() {
    this.handleResize();
  },
  computed: {
    ...mapState({
      isLoading(state, getters) {
        return getters["isLoading"];
      },
    }),
    dropdownLinks() {
      return {
        devcon: {
          title: this.$t("developer_console"),
          href: this.getEnvUrl("CONSOLE_URL"),
          key: "console",
        },
        dmt: {
          title: this.$t("device_management_portal"),
          href: this.getEnvUrl("DEVICES_URL"),
          key: "devices",
        },
      };
    },
    footerLinks() {
      return [
        { text: this.$t("footerTermsAndConditions"), url: sastLinks.TERMS_AND_CONDITIONS_URL },
        { text: this.$t("footerPrivacyPolicy"), url: sastLinks.PRIVACY_POLICY_URL },
        { text: this.$t("footerLegal"), url: sastLinks.LEGAL_URL },
        { text: this.$t("footerImprint"), url: sastLinks.IMPRINT_URL },
        { text: this.$t("footerReportInfringement"), url: sastLinks.REPORT_INFRIGNEMENT_URL }
      ];
    },
  },
  methods: {
    logOut() {
      this.$keycloak.logoutFn();
    },
    pushToRouter(tab) {
      switch (tab) {
        case "profile":
          this.$router.push({ name: "my-profile" });
          break;
        case "company":
          this.$router.push({ name: "my-company" });
          break;
        case "management":
          this.$router.push({ name: "manage-users" });
          break;
        case "notificationSettings":
          this.$router.push({ name: "notification-settings" });
          break;
      }
    },
    getEnvUrl(url) {
      return sastLinks[config().env.toUpperCase()][url];
    },
    handleResize() {
      const logoMobile = document
        .getElementsByClassName("logo--mobile")
        .item(0);
      const logoDesktop = document
        .getElementsByClassName("logo--desktop")
        .item(0);
      logoMobile.classList.remove("d-xl-none");
      logoDesktop.classList.remove("d-none", "d-xl-flex");
      if (this.$vuetify.breakpoint.mobile) {
        logoMobile.style.display = "inline";
        logoDesktop.style.display = "none";
      } else {
        logoMobile.style.display = "none";
        logoDesktop.style.display = "inline";
      }
    },
  },
  beforeMount() {
    this.$store.dispatch(commonActionTypes.LOAD_COUNTRIES);
    this.$store.dispatch(
      ACCOUNT_NAMESPACE + "/" + accountActionTypes.GET_PERMISSIONS_FROM_TOKEN
    );
    this.isDeveloper = this.$store.state.account.isDeveloper;
    this.isIntegrator = this.$store.state.account.isIntegrator;
    this.canViewEcosystemUsers =
      this.$store.state.account.canViewEcosystemUsers;
    this.canEditEcosystemUsers =
      this.$store.state.account.canEditEcosystemUsers;
    this.canDeleteEcosystemCompanies =
      this.$store.state.account.canDeleteEcosystemCompanies;
    this.canBypassPjecoScreening =
      this.$store.state.account.canBypassPjecoScreening;
    this.canManageCompanyUsers =
      this.$store.state.account.canManageCompanyUsers;
    this.canViewEcosystemCompanies =
      this.$store.state.account.canViewEcosystemCompanies;
    this.canEditEcosystemCompanies =
      this.$store.state.account.canEditEcosystemCompanies;
    this.canManageCompanyRegistrations =
      this.$store.state.account.canManageCompanyRegistrations;
    this.canViewEcosystemCountries =
      this.$store.state.account.canViewEcosystemCountries;
    this.canTriggerTermsAndConditionsUpdate =
      this.$store.state.account.canTriggerTermsAndConditionsUpdate;
    this.activeTab = this.$route.meta.activeTab;

    if (this.canManageCompanyUsers) {
      this.nav.push({ title: "notificationSettings", icon: "$bell" });
    }

    if (this.canManageCompanyUsers) {
      this.nav.push({ title: "management", icon: "$team" });
    }
  },
  watch: {
    $route(to) {
      this.activeTab = to.meta.activeTab;
    },
  },
};
</script>
