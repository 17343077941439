const userObjectTypes = {
  USER: "USER",
  INVITATION: "INVITATION",
};

const permissionNames = {
  ECOSYSTEM_COMPANY_MANAGER: "isEcosystemCompanyManager",
  ORDER_DESK_MANAGER: "isOrderDeskManager",
  COMPANY_ACCOUNT_MANAGER: "isCompanyAccountManager",
  PROJECT_MANAGER: "isProjectManager",
  PAYMENT_MANAGER: "isPaymentManager",
  CAMERA_MANAGER: "isCameraManager",
};

const orderDeskContact = {
  NAME: "Order fulfillment desk",
  EMAIL: "support@azena.com",
};

const companyTypes = {
  MANUFACTURER: "MANUFACTURER",
  DEVELOPER: "DEVELOPER",
  INTEGRATOR: "INTEGRATOR",
};

const sastLinks = {
  IMPRINT_URL: "https://legal.azena.com/en/imprint",
  TERMS_AND_CONDITIONS_URL: "https://legal.azena.com/terms",
  PRIVACY_POLICY_URL: "https://legal.azena.com/en/privacy-policy",
  LEGAL_URL: "https://legal.azena.com/legal-notice",
  REPORT_INFRIGNEMENT_URL: "https://legal.azena.com/report-infringement",
  LICENSE_URL: "/licenses.txt",
  SUPPORT_URL: "https://support.azena.com/hc",
  LOCALHOST: {
    DEVICES_URL: "https://devices.azena.com",
    CONSOLE_URL: "https://console.azena.com",
    COUNTRIES_URL: "https://countries.azena.com",
  },
  SANDBOX: {
    DEVICES_URL: "https://devices.camdroid.io",
    CONSOLE_URL: "https://store.hybris-sandbox.sastiot.com",
    COUNTRIES_URL: "https://countries.sandbox.securityandsafetydev.com",
  },
  DEV: {
    DEVICES_URL: "https://devices.camdroid.io",
    CONSOLE_URL: "https://console.camdroid.io",
    COUNTRIES_URL: "https://countries.camdroid.io",
  },
  DEMO: {
    DEVICES_URL: "https://devices.securityandsafetydemo.io",
    CONSOLE_URL: "https://console.securityandsafetydemo.io",
    COUNTRIES_URL: "https://countries.securityandsafetydemo.io",
  },
  LIVE: {
    DEVICES_URL: "https://devices.azena.com",
    CONSOLE_URL: "https://console.azena.com",
    COUNTRIES_URL: "https://countries.azena.com",
  },
};

export {
  companyTypes,
  userObjectTypes,
  sastLinks,
  orderDeskContact,
  permissionNames,
};
